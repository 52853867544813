.pdfDocumentViewer {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  contain: content;
}

.controlPanel {
  display: flex;
  padding: var(--inner-spacing-2);
}

.zoomButtons {
  display: flex;
  gap: var(--inner-spacing-2);
  margin: 0 0 0 auto;
}

.content {
  position: relative;
  height: 100%;
  overflow: hidden;
  padding: var(--inner-spacing-4) var(--inner-spacing-4) 0;
}

.document {
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
}

.spinner {
  position: absolute;
  top: var(--inner-spacing-4);
  left: 50%;
  transform: translateX(-50%);
}

.citationHighlight {
  position: absolute;
  background: rgba(250, 244, 100, 0.3);
}
